import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/Home.vue";
import auth from "./auth"
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Login" }, 
  },
  /*{
    path: "/",
    redirect: { name: "dashboard" },
    meta:{layout:"DefaultLayout"}
    
  },*/
  {
    path: '/accounts/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    beforeEnter: (to, from, next) => {
      let userData = localStorage.getItem("userData");
      if (userData) {
        // this.$router.push("/dashboard")
        next("/dashboard")
      } else {
        next()
        return false;
      }
    }
  },
  {
    path: "/about",
    name: "About",
    meta: { layout: "DefaultLayout", requiresAuth: true },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { layout: "DefaultLayout", requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Dashboard.vue"),
  },
  {
    path: "/calendar",
    name: "calendar",
    meta: { layout: "DefaultLayout", requiresAuth: true },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Calendar.vue"),
  },
  {
    path: "/map",
    name: "mapa",
    meta: { layout: "DefaultLayout", requiresAuth: true },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/Map.vue"),
  },
  {
    path: "/reports",
    name: "reports",
    meta: { layout: "DefaultLayout", requiresAuth: true },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Reports.vue"),
  },
  {
    path: "/notifications",
    name: "notifications",
    meta: { layout: "DefaultLayout", requiresAuth: true },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Notifications.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(auth)

export default router;
