import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        //primary: "#6cad4f",
        primary: "#71cfdb",
        secondary: "#519cd2",
        terciary: "#4771A6",
        accent: "#519cd2",
        notselected: "#7598c4",
        error: "#676b74",
        dash: "#7da8ac",
        header: "#247D72",
      },
    },
  },
});
