function auth(to, from, next) {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    let userData = localStorage.getItem("userData");
    if (userData) {
      userData = JSON.parse(userData); 
      next();
    } else {
      next("/accounts/login");
    }
  } else if (to.path == "/logout") {
    localStorage.removeItem("userData");
    next("/");
  } else {
    //probablemente login
    console.log(to);
    next();
  }
}
export default auth;
